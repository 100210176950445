import { useDispatch } from 'react-redux'
import {
  showNotification as showNotificationAction,
  clearNotification as clearNotificationAction,
} from './reducer'

export const useNotification = () => {
  const dispatch = useDispatch()

  const displayNotification = notification =>
    dispatch(showNotificationAction(notification))

  const clearNotification = () => dispatch(clearNotificationAction())

  return { displayNotification, clearNotification }
}
