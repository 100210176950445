import React from 'react'
import { Snackbar, Alert } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNotification } from '../notification/useNotification'

const defaultSx = {
  snackbar: {
    width: '100%',
    position: 'absolute',
    bottom: 'initial !important',
  },
}

function Notification() {
  const notification = useSelector(state => state.notification)
  const { clearNotification } = useNotification()

  const handleClose = (_, reason) => {
    reason !== 'clickaway' && clearNotification()
  }

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={notification.timeout}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={defaultSx.snackbar}
    >
      <Alert
        variant='filled'
        onClose={handleClose}
        severity={notification.type}
        sx={{ width: '100%' }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  )
}

export default Notification
