import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as payerAPI from '../api/payers'

const name = 'payer'

export const createPayer = createAsyncThunk(
  `${name}/create`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await payerAPI.createPayer(body)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

export const updatePayer = createAsyncThunk(
  `${name}/update`,
  async (body, { getState, rejectWithValue }) => {
    try {
      const { payer } = getState()
      const response = await payerAPI.updatePayer(payer.selected.id, body)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

export const fetchPayer = createAsyncThunk(
  `${name}/fetch`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await payerAPI.fetchPayer(id)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

/**
 * {
  businessName: null,
  contact: {
    fullName: '',
    email: '',
    phone: '',
  },
  businessAddress: {
    line1: '',
    line2: null,
    city: '',
    state: '',
    postalCode: '',
    country: '',
  },
  externalId: '',
  identityDocuments: [
    {
      type: '',
      value: '',
    },
  ],
}
 */

const initialState = {
  selected: null,
  error: null,
  isLoading: false,
}

export const payerSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFormData: (state, { payload }) => {
      state.selected = { ...state.selected, ...payload }
    },
    resetFormData: state => {
      state.selected = initialState.selected
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
  },
  extraReducers: {
    [createPayer.pending]: state => {
      state.isLoading = true
    },
    [createPayer.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [createPayer.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = payload
    },
    [updatePayer.pending]: state => {
      state.isLoading = true
    },
    [updatePayer.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [updatePayer.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = payload
    },
    [fetchPayer.pending]: state => {
      state.isLoading = true
    },
    [fetchPayer.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [fetchPayer.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setFormData } = payerSlice.actions

export default payerSlice.reducer
