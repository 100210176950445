import { EVENT } from './constants'
import { fullStoryIdentify, fullStoryTrack } from '../fullStory'

class EventService {
  handle({ eventName, eventProperties = {} }) {
    switch (eventName) {
      case EVENT.LOGIN: {
        const { payerId, ...props } = eventProperties
        if (payerId) {
          fullStoryIdentify(payerId, props)
        }
        break
      }
      case EVENT.PAYER_INFO_SUBMIT: {
        const { payerId, ...props } = eventProperties
        if (payerId) {
          fullStoryIdentify(payerId, props)
        }
        fullStoryTrack({ eventName, eventProperties })
        break
      }
      case EVENT.RECIPIENT_INFO_SUBMIT:
      case EVENT.DELIVERY_METHOD_SUBMIT:
      case EVENT.PAYMENT_METHOD_SUBMIT:
      case EVENT.DETAILS_SUBMIT:
      case EVENT.PAYMENT_SUBMIT: {
        fullStoryTrack({ eventName, eventProperties })
        break
      }
      default:
    }
  }
}

export default new EventService()
