const CONNECT_ENDPOINTS = {
  DOCUMENTS: '/documents',
  RECIPIENTS: '/recipients',
  PAYMENT_INTENTS: '/payment-intents',
  PAYMENT_METHODS: '/payment-methods',
  PAYMENTS: '/payments',
  PAYERS: '/payers',
}

const CONNECT_ENV_TO_API_URL_MAP = {
  local: 'http://localhost:3140/api',
  dev: 'https://cep.dev.plastiq.com/api',
  qa: 'https://cep.qa.plastiq.com/api',
  sandbox: 'https://connect.sandbox.plastiq.com/api',
  prod: 'https://connect.plastiq.com/api',
}

export { CONNECT_ENDPOINTS, CONNECT_ENV_TO_API_URL_MAP }
