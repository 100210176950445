import { post, get, patch } from './utils'
import { CONNECT_ENDPOINTS } from './constants'

const patchPaymentIntent = async (id, body) => {
  return patch(`${CONNECT_ENDPOINTS.PAYMENT_INTENTS}/${id}`, body)
}

const createPaymentIntent = async body => {
  return post(CONNECT_ENDPOINTS.PAYMENT_INTENTS, body)
}

const fetchPaymentIntent = async (id, queryString) => {
  const url = `${CONNECT_ENDPOINTS.PAYMENT_INTENTS}/${id}`
  return get(url, queryString)
}

export { fetchPaymentIntent, createPaymentIntent, patchPaymentIntent }
