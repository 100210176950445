import { CircularProgress } from '@mui/material'
import { Box } from '@mui/material'

const styles = () => ({
  loader: {
    textAlign: 'center',
    marginTop: '48px',
  },
})
const Loader = () => {
  const sx = styles()
  return (
    <Box sx={sx.loader}>
      <CircularProgress></CircularProgress>
    </Box>
  )
}

export default Loader
