import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as paymentIntentAPI from '../api/paymentIntents'

const name = 'paymentIntent'

export const createPaymentIntent = createAsyncThunk(
  `${name}/create`,
  async (body, { getState, rejectWithValue }) => {
    try {
      const { payer, paymentMethod, recipient } = getState()
      //TODO - Validate body
      const requestBody = {
        targetAmount: {
          value: parseFloat(body.amount),
          currency: 'USD',
        },
        details: {
          accountNumber: body.accountNumber,
          accountName: body.accountName,
          memo: body.memo,
        },
        payer: {
          id: payer.selected.id,
        },
        paymentMethod: {
          id: paymentMethod.selected.id,
        },
        recipient: {
          id: recipient.selected.id,
        },
      }
      const response = await paymentIntentAPI.createPaymentIntent(requestBody)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

export const getPaymentIntent = createAsyncThunk(
  `${name}/get`,
  async (id, { getState, rejectWithValue }) => {
    try {
      const { payer } = getState()
      const response = await paymentIntentAPI.fetchPaymentIntent(
        id,
        `payerId=${payer.selected.id}`,
      )
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

export const patchPaymentIntent = createAsyncThunk(
  `${name}/patch`,
  async (body, { getState, rejectWithValue }) => {
    try {
      const { payer, paymentIntent } = getState()
      //TODO - Validate body
      const requestBody = {
        targetAmount: {
          value: parseFloat(body.amount),
          currency: 'USD',
        },
        details: {
          accountNumber: body.accountNumber,
          accountName: body.accountName,
          memo: body.memo,
        },
        payer: {
          id: payer.selected.id,
        },
      }
      const response = await paymentIntentAPI.patchPaymentIntent(
        paymentIntent.selected.id,
        requestBody,
      )
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

/**
 * {
  id: '',
  targetAmount: {
    value: '',
    currency: '',
  },
  details: {
    accountName: '',
    accountNumber: '',
    memo: '',
  },
}
 */

const initialState = {
  selected: null,
  isLoading: false,
  error: null,
}

export const paymentIntentSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPaymentIntentFormData: (state, { payload }) => {
      state.selected = { ...state.selected, ...payload }
    },
    resetPaymentIntentFormData: state => {
      state.selected = initialState.selected
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
  },
  extraReducers: {
    [createPaymentIntent.pending]: state => {
      state.isLoading = true
    },
    [createPaymentIntent.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [createPaymentIntent.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = { ...state.selected, ...payload }
    },

    [getPaymentIntent.pending]: (state, { payload }) => {
      state.isLoading = true
    },
    [getPaymentIntent.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [getPaymentIntent.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = { ...state.selected, ...payload }
    },

    [patchPaymentIntent.pending]: state => {
      state.isLoading = true
    },
    [patchPaymentIntent.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [patchPaymentIntent.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = { ...state.selected, ...payload }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setPaymentIntentFormData,
  resetPaymentIntentFormData,
  setIsLoading,
} = paymentIntentSlice.actions

export default paymentIntentSlice.reducer
