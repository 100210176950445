import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Recipient from './'
import RecipientInformation from './pages/RecipientInformation'
import DeliveryInformation from './pages/DeliveryInformation'
import RecipientList from './pages/RecipientList'

function RecipientRoutes() {
  return (
    <Routes>
      <Route path='recipient' element={<Recipient />}>
        <Route index={true} element={<RecipientIndex />} />
        <Route path='business' element={<RecipientInformation />} />
        <Route path='delivery' element={<DeliveryInformation />} />
        <Route path='list' element={<RecipientList />} />
      </Route>
    </Routes>
  )
}

function RecipientIndex() {
  const recipientList = useSelector(state => state.recipient.list)

  if (recipientList.length) return <RecipientList />
  return <RecipientInformation />
}

export default RecipientRoutes
