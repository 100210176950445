import { WORKFLOW_TYPES } from '../constants'

export const mockRequest = async () =>
  await new Promise(resolve => setTimeout(resolve, 1400))

// TODO: can be moved to /messaging.js once redux store circular dependency has been removed
export const handlePostMessage = (
  response,
  parentOrigin,
  object,
  type = null,
) => {
  const { payload, error } = response

  const messageData = {
    object,
    status: getStatus(type, error),
    data: error || payload,
  }

  console.log(messageData, type)
  window.parent.postMessage(messageData, parentOrigin)
}

const getStatus = (type, error) => {
  if (type) return type.toUpperCase()
  // support previous implementation defaults
  return error ? 'ERROR' : 'SUCCESS'
}

export const isCardCaptureWorkflowType = workflow =>
  workflow === WORKFLOW_TYPES.CARD_CAPTURE
export const isCheckoutWorkflowType = workflow =>
  workflow === WORKFLOW_TYPES.CHECKOUT

// These params should follow Artemis naming conventions (constants/errors.js)
export const buildError = (
  errorMessage,
  code = 'VALIDATION_ERROR',
  message = 'Validation error',
) => {
  return {
    message,
    details: [{ message: errorMessage, code }],
  }
}

export const getFirstAndLastFromFullName = fullName => {
  const fullNameArr = fullName.split(' ')
  const firstName = fullNameArr.slice(0, -1).join(' ')
  const lastName = fullNameArr.slice(-1).join(' ')
  return { firstName, lastName }
}

export const getFormattedExpiration = (expMonth, expYear) =>
  `${expMonth}/${expYear.slice(-2)}`
