import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PaymentMethod from './'
import PaymentMethodInformation from './pages/PaymentMethodInformation'
import PaymentMethodList from './pages/PaymentMethodList'

function PaymentMethodRoutes() {
  return (
    <Routes>
      <Route path='payment-method' element={<PaymentMethod />}>
        <Route index={true} element={<PaymentMethodIndex />} />
        <Route path='info' element={<PaymentMethodInformation />} />
        <Route path='list' element={<PaymentMethodList />} />
      </Route>
    </Routes>
  )
}

function PaymentMethodIndex() {
  const paymentMethodList = useSelector(state => state.paymentMethod.list)

  if (paymentMethodList.length) return <PaymentMethodList />
  return <PaymentMethodInformation />
}

export default PaymentMethodRoutes
