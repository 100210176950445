import { ErrorOutline, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'

const styles = () => ({
  h1: {
    fontSize: '24px',
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingTop: '24px',
  },
  icon: { width: '84px', height: '84px', color: '#F07684', marginTop: '18px' },
  accordion: {
    width: '60%',
    margin: 'auto',
    marginTop: '18px',
  },
})

const Error = ({ error }) => {
  const sx = styles()
  return (
    <>
      <Box align='center'>
        <Typography sx={sx.h1} variant='h1'>
          Error
        </Typography>
        <ErrorOutline sx={sx.icon} />
        <Box>An error has occurred. Please contact support. </Box>
        <Box sx={sx.accordion}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='error-content'
              id='error-header'
            >
              <Typography>{error.message}</Typography>
            </AccordionSummary>
            {error?.details?.length && (
              <AccordionDetails align='left'>
                <div>
                  <strong>{error.details[0].code}</strong>
                </div>
                <div>{error.details[0].message}</div>
              </AccordionDetails>
            )}
          </Accordion>
        </Box>
      </Box>
    </>
  )
}

export default Error
