import { Outlet } from 'react-router-dom'

function PaymentFlow() {
  // payment-scoped UI can go here

  return (
    <>
      <Outlet />
    </>
  )
}

export default PaymentFlow
