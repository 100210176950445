import { Outlet } from 'react-router-dom'

function PaymentMethodFlow() {
  // payment-method-scoped UI can go here
  // ex. breadcrumbs, nav, etc

  return (
    <>
      <Outlet />
    </>
  )
}

export default PaymentMethodFlow
