import { Outlet } from 'react-router-dom'

function PayerFlow() {
  // payer-scoped UI can go here
  // ex. breadcumbs, nav, etc
  return (
    <>
      <Outlet />
    </>
  )
}

export default PayerFlow
