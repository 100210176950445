import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { PayerInformation as PayerInformationInput } from '@plastiq/react-components'
import { handlePostMessage } from '../../utils/helpers'
import { createPayer, updatePayer } from '../reducer'
import { useNotification } from '../../notification/useNotification'
import BodyAnimation from '../../components/BodyAnimation'
import eventService from '../../utils/event/EventService'
import { EVENT } from '../../utils/event/constants'

function PayerInformation() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const payer = useSelector(state => state.payer.selected)
  const presetRecipient = useSelector(state => state.app.presetRecipient)
  const workflowType = useSelector(state => state.app.workflowType)
  const { parentOrigin, theme = {} } = useSelector(state => state.app)
  const { displayNotification } = useNotification()

  const form = useForm({
    defaultValues: {
      businessName: payer?.businessName,
      firstName: payer?.contact?.firstName,
      lastName: payer?.contact?.lastName,
      phoneNumber: payer?.contact?.phone,
      email: payer?.contact?.email,
    },
  })
  const {
    formState: { isDirty },
  } = form

  const handleOnSubmit = async data => {
    const { firstName, lastName, businessName, phoneNumber, email } = data
    const newPayer = {
      businessName: businessName || null,
      contact: { firstName, lastName, phone: phoneNumber, email },
    }

    let response = null
    if (payer?.id) {
      if (isDirty) response = await dispatch(updatePayer(newPayer))
      // TODO: if recipient list, route there
      // TODO: should we route to a primary branch component that handles checking for an existing list vs no recipients for a payer, or manage that here?
    } else response = await dispatch(createPayer(newPayer))

    const { error, payload = {} } = response
    if (response) handlePostMessage(response, parentOrigin, 'PAYER')
    // TODO: render error message from redux store?
    if (error)
      displayNotification({ message: 'Error creating payer', type: 'error' })

    const payerId = payload.id || payer?.id

    eventService.handle({
      eventName: EVENT.PAYER_INFO_SUBMIT,
      eventProperties: {
        payerId,
        businessName,
        email,
        ...(payer?.contact?.email && { existingEmail: payer?.contact?.email }),
        lastName,
        workflowType,
        hasError: Boolean(error),
      },
    })

    if (!error) {
      if (presetRecipient) {
        navigate('/payment-method')
      } else {
        navigate('/recipient')
      }
    }
  }

  const handleSubmit = form.handleSubmit(handleOnSubmit)

  return (
    <BodyAnimation>
      <form onSubmit={handleSubmit}>
        <PayerInformationInput
          theme={theme}
          form={form}
          onSubmit={handleSubmit}
          firstName={{ required: true }}
          lastName={{ required: true }}
          businessName={{ required: false }}
          phoneNumber={{ required: true }}
          email={{ required: true }}
        />
      </form>
    </BodyAnimation>
  )
}

export default PayerInformation
