import axios from 'axios'

const makeApiCall = (method, url, params) => {
  const { data, queryString } = params
  const requestUrl = queryString ? `${url}?${queryString}` : url
  return axios({
    method,
    url: requestUrl,
    data,
  })
}

export const post = (url, data, options) =>
  makeApiCall('post', url, { data, options })

export const patch = (url, data) => makeApiCall('patch', url, { data })

export const get = (url, queryString) =>
  makeApiCall('get', url, { queryString })
