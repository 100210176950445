import { useSelector } from 'react-redux'
import { PaymentSuccess } from '@plastiq/react-components'

import BodyAnimation from '../../components/BodyAnimation'
import { handlePostMessage } from '../../utils/helpers'

function Success() {
  const payment = useSelector(state => state.payment.selected)
  const paymentMethod = useSelector(state => state.paymentMethod.selected.data)
  const successConfig = useSelector(state => state.paymentSuccess.config)
  const isCtaVisible = useSelector(state => state.app.handlers.onComplete)
  const { parentOrigin } = useSelector(state => state.app)
  const {
    id: paymentId,
    sourceDescriptor,
    sourceAmount,
    targetAmount,
  } = payment
  const { brand, last4 } = paymentMethod
  const { ctaLabel, contact: { email: contactEmail } = {} } = successConfig

  const handleCtaClick = () => {
    handlePostMessage({}, parentOrigin, 'PAYMENT', 'COMPLETE')
  }

  return (
    <BodyAnimation>
      <PaymentSuccess
        paymentAmount={targetAmount.value}
        currency={sourceAmount.currency}
        totalAmount={sourceAmount.value}
        paymentMethodBrand={brand}
        paymentMethodLast4={last4}
        paymentId={paymentId}
        paymentDescriptor={sourceDescriptor}
        isCtaVisible={isCtaVisible}
        contactEmail={contactEmail}
        ctaLabel={ctaLabel}
        onCtaClick={handleCtaClick}
      />
    </BodyAnimation>
  )
}

export default Success
