import { post, get } from './utils'
import { CONNECT_ENDPOINTS } from './constants'

const createRecipient = async body => {
  return post(CONNECT_ENDPOINTS.RECIPIENTS, body)
}

const fetchRecipient = async id => {
  const url = `${CONNECT_ENDPOINTS.RECIPIENTS}/${id}`
  return get(url)
}

const fetchRecipientList = async payerId => {
  const url = `${CONNECT_ENDPOINTS.RECIPIENTS}?payerId=${payerId}`
  return get(url)
}

export { createRecipient, fetchRecipient, fetchRecipientList }
