import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Card as CardInput } from '@plastiq/react-components'

import { setSelectedData, addToList } from '../reducer'
import {
  handlePostMessage,
  isCardCaptureWorkflowType,
} from '../../utils/helpers'
import { useNotification } from '../../notification/useNotification'
import eventService from '../../utils/event/EventService'
import { EVENT } from '../../utils/event/constants'

function PaymentMethodInformation() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()

  const appConfig = useSelector(state => state.app)
  const isCardCaptureWorkflow = isCardCaptureWorkflowType(
    appConfig.workflowType,
  )
  const isStart = isCardCaptureWorkflow || state?.prevRoute?.pathname === '/'
  const payer = useSelector(state => state.payer.selected)
  const paymentMethodList = useSelector(state => state.paymentMethod.list)
  const { parentOrigin, theme = {} } = useSelector(state => state.app)
  const { displayNotification } = useNotification()

  const styles = {
    container: {
      margin: 0,
      padding: 0,

      '@media screen and (min-width: 768px)': {
        padding: '48px',
      },
    },
    iframe: {
      width: '1px',
      minWidth: '100%',
      border: 'none',
      // since this is an iframe within an iframe, we need to set the height explicitly
      // in order for the widget container to grow to the correct height
      // To get the correct height, look at the height of this element using Chrome inspector:
      // <body class="vsc-initialized">
      // Add 8px to show CTA box shadow
      minHeight: '570px',
    },
  }

  const handleOnSuccess = cardData => {
    dispatch(setSelectedData(cardData))
    handlePostMessage({ payload: cardData }, parentOrigin, 'PAYMENT_METHOD')
    !isCardCaptureWorkflow && dispatch(addToList(cardData))

    eventService.handle({
      eventName: EVENT.PAYMENT_METHOD_SUBMIT,
      eventProperties: {
        brand: cardData?.data?.brand,
        subType: cardData?.data?.subType,
        country: cardData?.data?.billingAddress?.country,
        state: cardData?.data?.billingAddress?.state,
        isCardCaptureWorkflow,
      },
    })

    if (isCardCaptureWorkflow) {
      displayNotification({
        message: 'Payment method added',
        type: 'success',
      })
    } else navigate('/payment/details')
  }

  const handleOnError = error => {
    handlePostMessage({ error }, parentOrigin, 'PAYMENT_METHOD')

    eventService.handle({
      eventName: EVENT.PAYMENT_METHOD_SUBMIT,
      eventProperties: {
        hasError: true,
        message: error?.message,
      },
    })

    // TODO: render error message from redux store?
    // TODO: should snackbar notifications originate within the payment-method-components application?
    displayNotification({
      message: error.message,
      type: 'error',
    })
  }

  const handleClickBack = () => {
    if (!paymentMethodList?.length) {
      handlePostMessage({}, parentOrigin, 'PAYMENT_METHOD', 'BACK')
    }

    navigate(-1)
  }

  return (
    <>
      <CardInput
        {...appConfig}
        referrer={appConfig.referrer}
        theme={theme}
        payerId={payer.id}
        onSuccess={handleOnSuccess}
        onError={handleOnError}
        onClickBack={handleClickBack}
        isStart={isStart}
        disableSubmitOnSuccess={isCardCaptureWorkflow}
        submitButtonText={isCardCaptureWorkflow ? 'Submit' : 'Continue'}
        sx={styles}
        hideScrollbar={true}
      />
    </>
  )
}

export default PaymentMethodInformation
