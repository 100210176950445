import { createSlice } from '@reduxjs/toolkit'

const name = 'app'

const initialState = {
  clientSecret: '',
  publicKey: '',
  parentOrigin: '',
  workflowType: '',
  environment: '',
  referrer: '',
  theme: {},
  isLoading: true,
  error: null,
  prevContentHeight: 0,
}

export const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    setAppConfig: (state, { payload }) => {
      const {
        clientSecret,
        publicKey,
        parentOrigin,
        workflowType,
        environment,
        theme,
        presetRecipient,
        presetPayer,
        handlers,
        contact,
      } = payload
      state.clientSecret = clientSecret
      state.publicKey = publicKey
      state.parentOrigin = parentOrigin
      state.referrer = window.document.referrer
      state.workflowType = workflowType
      state.environment = environment
      state.theme = theme
      state.presetRecipient = presetRecipient
      state.presetPayer = presetPayer
      state.handlers = handlers
      state.contact = contact
    },
    getAppConfigHeaders: state => {
      const { clientSecret, publicKey, parentOrigin } = state
      return { clientSecret, publicKey, parentOrigin }
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setPrevContentHeight: (state, { payload }) => {
      state.prevContentHeight = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setAppConfig,
  getAppConfigHeaders,
  setIsLoading,
  setError,
  setPrevContentHeight,
} = appSlice.actions

export default appSlice.reducer
