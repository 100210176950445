import { configureStore } from '@reduxjs/toolkit'
import payerReducer from './payer/reducer'
import paymentReducer from './payment/paymentReducer'
import paymentIntentReducer from './payment/paymentIntentReducer'
import paymentReviewReducer from './payment/paymentReviewReducer'
import paymentSuccessReducer from './payment/paymentSuccessReducer'
import paymentMethodReducer from './payment-method/reducer'
import recipientReducer from './recipient/reducer'
import appReducer from './app/reducer'
import notificationReducer from './notification/reducer'

export default configureStore({
  reducer: {
    payer: payerReducer,
    payment: paymentReducer,
    paymentIntent: paymentIntentReducer,
    paymentReview: paymentReviewReducer,
    paymentSuccess: paymentSuccessReducer,
    paymentMethod: paymentMethodReducer,
    recipient: recipientReducer,
    app: appReducer,
    notification: notificationReducer,
  },
})
