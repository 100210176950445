import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PaymentReview } from '@plastiq/react-components'

import { createPayment } from '../paymentReducer'
import { getFormattedExpiration, handlePostMessage } from '../../utils/helpers'
import { useNotification } from '../../notification/useNotification'
import BodyAnimation from '../../components/BodyAnimation'
import eventService from '../../utils/event/EventService'
import { EVENT } from '../../utils/event/constants'

function Review() {
  const navigate = useNavigate()
  const form = useForm()

  const dispatch = useDispatch()
  const { state } = useLocation()
  const isStart = state?.prevRoute?.pathname === '/'
  const paymentIntent = useSelector(state => state.paymentIntent.selected)
  const payer = useSelector(state => state.payer.selected)
  const recipient = useSelector(state => state.recipient.selected)
  const paymentMethod = useSelector(state => state.paymentMethod.selected)
  const { showDeliveryInformation = false } = useSelector(
    state => state.paymentReview.config,
  )
  const { parentOrigin, theme = {} } = useSelector(state => state.app)
  const { displayNotification } = useNotification()

  const createdAt = new Date(paymentIntent.createdAt)
  const deliveryDate = new Date(paymentIntent.deliveryDate)

  const payment = {
    createdAt,
    deliveryDate,
    details: paymentIntent.details,
    fees: paymentIntent.fees,
    payer: payer,
    paymentMethod: {
      type: 'CARD',
      cardBrand: paymentMethod.data.brand.toUpperCase(),
      cardLast4: paymentMethod.data.last4,
      expiration: getFormattedExpiration(
        paymentMethod.data.expirationMonth,
        paymentMethod.data.expirationYear,
      ),
    },
    processingDate: new Date(),
    recipient: {
      address: recipient.businessAddress,
      name: recipient.businessName,
      categoryId: recipient.categoryId,
      contact: {
        name: recipient.firstName + ' ' + recipient.lastName,
        address: recipient.businessAddress,
      },
      receivingMethod: recipient.receivingMethod,
    },
    sourceAmount: paymentIntent.sourceAmount,
    sourceDescriptor: 'PQ *' + recipient.businessName.substring(0, 8),
    status: paymentIntent.status,
    targetAmount: paymentIntent.targetAmount,
  }

  const handleOnSubmit = async data => {
    const response = await dispatch(createPayment())
    handlePostMessage(response, parentOrigin, 'PAYMENT')

    const { error, payload: responsePayload = {} } = response

    eventService.handle({
      eventName: EVENT.PAYMENT_SUBMIT,
      eventProperties: {
        paymentId: responsePayload.id,
        deliveryDate: responsePayload.deliveryDate,
        status: responsePayload.status,
        hasError: Boolean(error),
      },
    })

    if (error) {
      displayNotification({ message: 'Error creating payment', type: 'error' })
      navigate('/payment/error')
    } else navigate('/payment/success')
  }

  const handleClickBack = () => {
    handlePostMessage({}, parentOrigin, 'PAYMENT', 'BACK')
    navigate(-1)
  }

  const handleSubmit = form.handleSubmit(handleOnSubmit)
  return (
    <>
      <BodyAnimation>
        <form onSubmit={handleSubmit}>
          <PaymentReview
            theme={theme}
            isStart={isStart}
            form={form}
            onClickBack={handleClickBack}
            onClickEdit={() => navigate('/payment/details')}
            onSubmit={handleSubmit}
            payment={payment}
            config={{
              showDeliveryInformation,
              editAmount: !paymentIntent?.readOnly,
            }}
          />
        </form>
      </BodyAnimation>
    </>
  )
}

export default Review
