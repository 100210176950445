const CONNECT_STATUS_REASONS = {
  PAYMENT_METHOD_REQUIRES_TIN: 'PAYMENT_METHOD_REQUIRES_TIN',
}

const WORKFLOW_TYPES = {
  CHECKOUT: 'checkout',
  CARD_CAPTURE: 'cardCapture',
}

export { CONNECT_STATUS_REASONS, WORKFLOW_TYPES }
