import { motion } from 'framer-motion'

const BodyAnimation = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.98, y: '24px' }}
    animate={{
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        ease: [0.59, -0.31, 0.43, 1.26], // ease in and out back
        delay: 0.26,
        duration: 0.3,
      },
    }}
    exit={{
      y: '-20px',
      opacity: 0,
      scale: 1.02,
      transition: {
        ease: 'easeInOut',
        duration: 0.25,
      },
    }}
  >
    {children}
  </motion.div>
)

export default BodyAnimation
