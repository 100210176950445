import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as documentAPI from '../api/documents'

const name = 'document'

export const createDocument = createAsyncThunk(
  `${name}/create`,
  async (body, { getState, rejectWithValue }) => {
    try {
      const response = await documentAPI.createDocument(body)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

export const uploadDocument = createAsyncThunk(
  `${name}/upload`,
  async (body, { getState, rejectWithValue }) => {
    try {
      const {
        uploadURL,
        file,
        filename,
        Key,
        success_action_status,
        bucket,
        Policy,
      } = body
      const formdata = new FormData()
      formdata.append('Key', Key)
      formdata.append('success_action_status', success_action_status)
      formdata.append('bucket', bucket)
      formdata.append('X-Amz-Algorithm', body['X-Amz-Algorithm'])
      formdata.append('X-Amz-Credential', body['X-Amz-Credential'])
      formdata.append('X-Amz-Date', body['X-Amz-Date'])
      formdata.append('X-Amz-Security-Token', body['X-Amz-Security-Token'])
      formdata.append('Policy', Policy)
      formdata.append('X-Amz-Signature', body['X-Amz-Signature'])
      formdata.append('file', file, filename)

      const response = await documentAPI.uploadDocument(uploadURL, formdata)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

const initialState = {
  selected: null,
  uploaded: null,
  isLoading: false,
  error: null,
}

export const documentSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: {
    [createDocument.pending]: state => {
      state.isLoading = true
    },
    [createDocument.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [createDocument.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = payload
    },
    [createDocument.pending]: state => {
      state.isLoading = true
    },
    [createDocument.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [createDocument.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.uploaded = payload
    },
  },
})

export default documentSlice.reducer
