import { createSlice } from '@reduxjs/toolkit'

const name = 'paymentSuccess'

const initialState = {
  config: {
    ctaLabel: 'Continue',
  },
}

export const paymentSuccessSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPaymentSuccessConfig: (state, { payload = {} }) => {
      state.config = payload
    },
    resetPaymentSuccessConfig: state => {
      state.config = initialState
    },
  },
})

export const { setPaymentSuccessConfig, resetPaymentSuccessConfig } =
  paymentSuccessSlice.actions

export default paymentSuccessSlice.reducer
