import { Routes, Route } from 'react-router-dom'
import Payment from './'
import Details from './pages/Details'
import Review from './pages/Review'
import Success from './pages/Success'
import Error from './pages/Error'

function PaymentRoutes() {
  return (
    <Routes>
      <Route path='payment' element={<Payment />}>
        <Route path='details' element={<Details />} />
        <Route path='review' element={<Review />} />
        <Route path='success' element={<Success />} />
        <Route path='error' element={<Error />} />
      </Route>
    </Routes>
  )
}

export default PaymentRoutes
