import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { RecipientList as RecipientListForm } from '@plastiq/react-components'
import { setFormData, resetFormData } from '../reducer'
import { handlePostMessage } from '../../utils/helpers'
import BodyAnimation from '../../components/BodyAnimation'
import eventService from '../../utils/event/EventService'
import { EVENT } from '../../utils/event/constants'

function RecipientList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const isStart = state?.prevRoute?.pathname === '/'
  const recipientList = useSelector(state => state.recipient.list)
  const recipient = useSelector(state => state.recipient.selected)
  const { parentOrigin, theme = {} } = useSelector(state => state?.app)
  const dropdownList = recipientList.map(recipient => ({
    label: recipient.businessName,
    value: recipient.id,
  }))
  const form = useForm()

  const selectedRecipient = {
    label: recipient?.businessName,
    value: recipient?.id,
  }

  const handleOnSubmit = data => {
    const { recipient: recipientId } = data
    const selectedRecipient = recipientList.find(
      recipient => recipient.id === recipientId,
    )

    dispatch(setFormData(selectedRecipient))

    eventService.handle({
      eventName: EVENT.RECIPIENT_INFO_SUBMIT,
      eventProperties: {
        recipientId,
      },
    })

    navigate('/payment-method')
  }

  const handleSubmit = form.handleSubmit(handleOnSubmit)

  const handleClickCreate = () => {
    dispatch(resetFormData())
    navigate('/recipient/business')
  }

  const handleClickBack = () => {
    handlePostMessage({}, parentOrigin, 'RECIPIENT', 'BACK')
    navigate(-1)
  }

  return (
    <BodyAnimation>
      <form onSubmit={handleSubmit}>
        <RecipientListForm
          theme={theme}
          isStart={isStart}
          form={form}
          onSubmit={handleSubmit}
          recipients={dropdownList}
          recipient={selectedRecipient}
          onClickCreate={handleClickCreate}
          onClickBack={handleClickBack} // TODO: always hide back if previous step was <setup>
        />
      </form>
    </BodyAnimation>
  )
}

export default RecipientList
