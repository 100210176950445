import { createSlice } from '@reduxjs/toolkit'

const name = 'notification'

const initialState = {
  open: false,
  type: 'info',
  message: '',
  timeout: 5000,
}

export const NotificationSlice = createSlice({
  name,
  initialState,
  reducers: {
    showNotification: (state, { payload }) => ({
      ...initialState,
      ...payload,
      open: true,
    }),
    clearNotification: state => ({ ...state, open: false }),
  },
})

export const { showNotification, clearNotification } = NotificationSlice.actions

export default NotificationSlice.reducer
