import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { RecipientInformation as RecipientInformationInput } from '@plastiq/react-components'

import { setFormData } from '../reducer'
import { handlePostMessage } from '../../utils/helpers'
import BodyAnimation from '../../components/BodyAnimation'
import eventService from '../../utils/event/EventService'
import { EVENT } from '../../utils/event/constants'

function RecipientInformation() {
  const form = useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const isStart = state?.prevRoute?.pathname === '/'
  const recipient = useSelector(state => state?.recipient?.selected)
  const recipientList = useSelector(state => state.recipient.list)
  const { parentOrigin, theme = {} } = useSelector(state => state?.app)

  const handleOnSubmit = async data => {
    const {
      recipientName: businessName,
      line1,
      line2,
      subCategory: categoryId,
      city,
      state,
      country,
      postalCode,
      email,
      phone,
    } = data
    dispatch(
      setFormData({
        businessName,
        categoryId,
        businessAddress: {
          line1,
          line2,
          city,
          state,
          postalCode,
          country,
        },
        contact: {
          email,
          phone,
        },
      }),
    )

    eventService.handle({
      eventName: EVENT.RECIPIENT_INFO_SUBMIT,
      eventProperties: {
        businessName,
        categoryId,
        state,
        country,
      },
    })

    navigate('/recipient/delivery')
  }

  const handleSubmit = form.handleSubmit(handleOnSubmit)

  const handleClickBack = () => {
    if (!recipientList?.length) {
      handlePostMessage({}, parentOrigin, 'RECIPIENT', 'BACK')
    }

    navigate(-1)
  }

  return (
    <BodyAnimation>
      <form onSubmit={handleSubmit}>
        <RecipientInformationInput
          theme={theme}
          isStart={isStart}
          form={form}
          onClickBack={handleClickBack}
          onSubmit={handleSubmit}
          recipientName={{
            value: recipient?.businessName,
            isRequired: false,
          }}
          category={{ value: recipient?.category, isRequired: true }}
          subCategory={{ value: recipient?.subCategory, isRequired: true }}
          country={{
            value: recipient?.businessAddress?.country || 'US',
            isRequired: true,
          }}
          line1={{
            value: recipient?.businessAddress?.line1,
            isRequired: true,
          }}
          line2={{
            value: recipient?.businessAddress?.line2,
            isRequired: false,
          }}
          city={{ value: recipient?.businessAddress?.city, isRequired: true }}
          state={{
            value: recipient?.businessAddress?.state,
            isRequired: true,
          }}
          postalCode={{
            value: recipient?.businessAddress?.postalCode,
            isRequired: true,
          }}
          email={{ value: recipient?.contact?.email, isRequired: false }}
          phone={{
            value: recipient?.contact?.phone,
            isRequired: true,
          }}
        />
      </form>
    </BodyAnimation>
  )
}

export default RecipientInformation
