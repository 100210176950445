import { Outlet } from 'react-router-dom'

function RecipientFlow() {
  // recipient-scoped UI can go here
  // ex. breadcrumbs, nav, etc

  return (
    <>
      <Outlet />
    </>
  )
}

export default RecipientFlow
