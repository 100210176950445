import { createSlice } from '@reduxjs/toolkit'

const name = 'paymentReview'

const initialState = {
  config: { showDeliveryInformation: false },
}

export const paymentReviewSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPaymentReviewConfig: (state, { payload = {} }) => {
      state.config = payload
    },
    resetPaymentReviewConfig: state => {
      state.config = initialState
    },
  },
})

export const { setPaymentReviewConfig, resetPaymentReviewConfig } =
  paymentReviewSlice.actions

export default paymentReviewSlice.reducer
