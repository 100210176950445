import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as paymentAPI from '../api/payments'

const name = 'payment'

export const createPayment = createAsyncThunk(
  `${name}/create`,
  async (body, { getState, rejectWithValue }) => {
    try {
      const { payer, paymentIntent } = getState()
      //TODO - Validate body
      const requestBody = {
        payer: {
          id: payer.selected.id,
        },
        paymentIntent: {
          id: paymentIntent.selected.id,
        },
      }
      const response = await paymentAPI.createPayment(requestBody)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

export const getPayments = createAsyncThunk(
  `${name}/get`,
  async (id, { getState, rejectWithValue }) => {
    try {
      const { payer } = getState()
      const response = await paymentAPI.getPayments(payer.selected.id)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

const initialState = {
  selected: null,
  isLoading: false,
  error: null,
}

export const paymentSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPaymentFormData: (state, { payload }) => {
      state.selected = { ...state.selected, ...payload }
    },
    resetPaymentFormData: state => {
      state.selected = initialState.selected
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
  },
  extraReducers: {
    [createPayment.pending]: state => {
      state.isLoading = true
    },
    [createPayment.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [createPayment.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = payload
    },
    [getPayments.pending]: state => {
      state.isLoading = true
    },
    [getPayments.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [getPayments.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = {
        id: payload[0].id,
        targetAmount: {
          value: payload[0].targetAmount.value,
          currency: payload[0].targetAmount.currency,
        },
        details: {
          accountName: payload[0].details.accountName,
          accountNumber: payload[0].details.accountNumber,
          memo: payload[0].details.memo,
        },
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPaymentFormData, resetPaymentFormData, setIsLoading } =
  paymentSlice.actions

export default paymentSlice.reducer
