import { post } from './utils'
import { CONNECT_ENDPOINTS } from './constants'

const createDocument = async body => {
  return post(CONNECT_ENDPOINTS.DOCUMENTS, body)
}

const uploadDocument = async (uploadUrl, body) => {
  return post(uploadUrl, body)
}

export { createDocument, uploadDocument }
