import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as paymentMethodAPI from '../api/paymentMethods'

const name = 'paymentMethod'

export const fetchPaymentMethodList = createAsyncThunk(
  `${name}/list`,
  async (payerId, { rejectWithValue }) => {
    try {
      const response = await paymentMethodAPI.fetchPaymentMethodList(payerId)
      const cards = response.data.data.filter(
        paymentMethod => paymentMethod.type === 'CARD',
      )
      return cards
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

const initialState = {
  selected: null,
  list: [],
  isLoading: false,
  error: null,
}

export const paymentMethodSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFormData: (state, { payload }) => {
      state.selected = { ...state.selected, ...payload }
    },
    setSelectedData: (state, { payload }) => {
      state.selected = payload
    },
    addToList: (state, { payload }) => {
      state.list.push(payload)
    },
    resetFormData: state => {
      state.selected = initialState.selected
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
  },
  extraReducers: {
    [fetchPaymentMethodList.pending]: state => {
      state.isLoading = true
    },
    [fetchPaymentMethodList.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [fetchPaymentMethodList.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.list = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setFormData,
  setSelectedData,
  resetFormData,
  setIsLoading,
  addToList,
} = paymentMethodSlice.actions

export default paymentMethodSlice.reducer
