import { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import store from '../store'
import { setPrevContentHeight } from '../app/reducer'
import FooterAnimation from './FooterAnimation'
import { WorkflowDevFooter } from '@plastiq/react-components'
import { useTheme } from '@mui/material/styles'

// TODO: Improve performance: renders more than once
const ContentAnimation = ({ children, height, isFooterVisible }) => {
  const theme = useTheme()
  const { prevContentHeight } = useSelector(state => state?.app)
  const shouldUpdateHeight = height > 0 && prevContentHeight !== height
  useEffect(() => {
    const shouldUpdateHeight = height > 0 && prevContentHeight !== height
    if (shouldUpdateHeight) {
      store.dispatch(setPrevContentHeight(height))
    }
  }, [height, prevContentHeight])
  return (
    <>
      <motion.div
        layout='size'
        style={{
          maxHeight: shouldUpdateHeight ? height : prevContentHeight,
          overflow: 'hidden',
          borderRadius: '12px',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          boxShadow: '0px 4px 12px 0px #00000012, 0px 2px 8px 0px #0000000D',
          marginBottom: theme.spacing(isFooterVisible ? 4 : 0),
        }}
        transition={{ ease: 'easeInOut', duration: 0.25 }}
      >
        {children}
      </motion.div>
      {isFooterVisible && (
        <FooterAnimation>
          <WorkflowDevFooter />
        </FooterAnimation>
      )}
    </>
  )
}

export default ContentAnimation
