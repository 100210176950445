// Handle inbound and outbound messaging with parent library
import {
  setFormData as setRecipientFormData,
  fetchRecipientList,
  fetchRecipient,
} from '../recipient/reducer'
import { setFormData as setPayerFormData, fetchPayer } from '../payer/reducer'
import {
  setFormData as setPaymentMethodFormData,
  fetchPaymentMethodList,
} from '../payment-method/reducer'
import { setPaymentIntentFormData } from '../payment/paymentIntentReducer'
import { setPaymentReviewConfig } from '../payment/paymentReviewReducer'
import { setAppConfig, setIsLoading, setError } from '../app/reducer'
import store from '../store'
import { isCheckoutWorkflowType } from './helpers'
import { widgetDefaultTheme } from '@plastiq/react-components'
import { setPaymentSuccessConfig } from '../payment/paymentSuccessReducer'

export const setupParentWindowMessaging = () => {
  const params = new URL(document.location).searchParams
  const parentOrigin = params.get('origin')
  handleInboundMessages(parentOrigin)

  const message = { type: 'iframeLoaded' }
  window.parent.postMessage(message, parentOrigin)
}

export const messageToParentWindow = (data, type) => {
  const { parentOrigin } = store.getState().app
  const message = { data, type }
  window.parent.postMessage(message, parentOrigin)
}

const handleInboundMessages = parentOrigin => {
  window.addEventListener('message', event => {
    if (event.origin === parentOrigin) {
      const message = event.data
      switch (message.type) {
        case 'loadConfig':
          const config = { parentOrigin, ...message.data }
          loadPartnerConfig(config)
          break
        default:
          break
      }
    }
  })
}

const loadPartnerConfig = async config => {
  const {
    recipient,
    payer,
    clientSecret,
    paymentIntent,
    paymentMethod,
    publicKey,
    workflow,
    environment,
    parentOrigin,
    theme,
    paymentReview,
    paymentSuccess,
    handlers,
    contact,
  } = config
  const newConfig = {
    clientSecret,
    publicKey,
    workflowType: workflow,
    parentOrigin,
    environment,
    theme: { ...widgetDefaultTheme, ...theme },
    presetRecipient: !!recipient?.id,
    presetPayer: !!payer?.id,
    handlers,
    contact,
  }
  store.dispatch(setAppConfig(newConfig))
  store.dispatch(setRecipientFormData(recipient))
  store.dispatch(setPayerFormData(payer))
  store.dispatch(setPaymentIntentFormData(paymentIntent))
  store.dispatch(setPaymentReviewConfig(paymentReview))
  store.dispatch(setPaymentSuccessConfig(paymentSuccess))
  store.dispatch(setPaymentMethodFormData(paymentMethod))

  const resourceRequests = []
  const isCheckoutWorkflow = isCheckoutWorkflowType(workflow)

  if (payer?.id) {
    resourceRequests.push(store.dispatch(fetchPayer(payer.id)))
    isCheckoutWorkflow &&
      resourceRequests.push(store.dispatch(fetchPaymentMethodList(payer.id)))
    isCheckoutWorkflow &&
      resourceRequests.push(store.dispatch(fetchRecipientList(payer.id)))
  }
  if (recipient?.id) {
    isCheckoutWorkflow &&
      resourceRequests.push(store.dispatch(fetchRecipient(recipient.id)))
  }
  const resources = await Promise.all(resourceRequests)
  // thunkAPI.rejectWithValue always resolves, does not reject/throw
  // Only show first error / 1 error at a time
  resources.some(resource => {
    if (resource.error) {
      const data = {
        message: `Loading ${resource.type.split('/')[0]} data failed`, // todo make this dynamic
        details: [resource.payload],
      }
      messageToParentWindow(data, 'error')
      store.dispatch(setError(data))
      return true
    } else {
      return false
    }
  })

  store.dispatch(setIsLoading(false))
}
