import axios from 'axios'
import { CONNECT_ENV_TO_API_URL_MAP } from '../constants'
let store

export const injectStore = _store => (store = _store)

const buildRequestHeaders = ({ publicKey, clientSecret, parentOrigin }) => {
  return {
    Authorization: `Bearer ${clientSecret}`,
    'Plastiq-Public-Key': publicKey,
    'Parent-Referer': parentOrigin,
  }
}

axios.interceptors.request.use(config => {
  // allow non-Artemis api calls to s3 bucket
  if (config.url.includes('s3.us-west-2.amazonaws.com/artemis')) {
    config.baseURL = config.url
    config.url = ''
  } else {
    const { app } = store.getState()
    config.baseURL = CONNECT_ENV_TO_API_URL_MAP[app.environment]
    config.headers = buildRequestHeaders(app)
  }
  return config
})
