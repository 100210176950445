import { post, get } from './utils'
import { CONNECT_ENDPOINTS } from './constants'

const createPayment = async body => {
  return post(CONNECT_ENDPOINTS.PAYMENTS, body)
}

const getPayments = async payerId => {
  const url = `${CONNECT_ENDPOINTS.PAYMENTS}/${payerId}`
  return get(url)
}

export { createPayment, getPayments }
