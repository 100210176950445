import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as recipientAPI from '../api/recipients'

const name = 'recipient'

export const createRecipient = createAsyncThunk(
  `${name}/create`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await recipientAPI.createRecipient(body)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

export const fetchRecipient = createAsyncThunk(
  `${name}/fetch`,
  async (recipientId, { rejectWithValue }) => {
    try {
      const response = await recipientAPI.fetchRecipient(recipientId)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

export const fetchRecipientList = createAsyncThunk(
  `${name}-list/fetch`,
  async (payerId, { rejectWithValue }) => {
    try {
      const response = await recipientAPI.fetchRecipientList(payerId)
      return response.data
    } catch (e) {
      return rejectWithValue({
        status: e.status,
        statusText: e.statusText,
        ...e.response.data,
      })
    }
  },
)

/**
 * {
    businessName: '',
    categoryId: '',
    businessAddress: {
      country: '',
      line1: '',
      city: '',
      state: '',
      postalCode: '',
    },
    contact: {
      email: '',
      phoneNumber: '',
    },
    receivingMethod: {
      type: '',
      country: '',
      beneficiaryName: '',
      routingNumber: '',
      accountNumber: '',
    },
    metadata: {},
  }
 */

const initialState = {
  selected: null,
  list: [],
  isLoading: false,
  error: null,
}

export const recipientSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFormData: (state, { payload }) => {
      state.selected = { ...state.selected, ...payload }
    },
    resetFormData: state => {
      state.selected = initialState.selected
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    addToList: (state, { payload }) => {
      state.list.push(payload)
    },
  },
  extraReducers: {
    [createRecipient.pending]: state => {
      state.isLoading = true
    },
    [createRecipient.rejected]: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
    [createRecipient.fulfilled]: (state, { payload }) => {
      state.created = payload
      state.selected = { ...state.selected, ...payload }
      state.list.push(payload)
      state.isLoading = false
    },
    [fetchRecipientList.pending]: state => {
      state.isLoading = true
    },
    [fetchRecipientList.rejected]: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
    [fetchRecipientList.fulfilled]: (state, { payload }) => {
      state.list = payload.data
      state.isLoading = false
    },
    [fetchRecipient.pending]: state => {
      state.isLoading = true
    },
    [fetchRecipient.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
    [fetchRecipient.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.selected = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setFormData, resetFormData, setIsLoading } =
  recipientSlice.actions

export default recipientSlice.reducer
