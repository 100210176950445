import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './App.css'
import 'iframe-resizer/js/iframeResizer.contentWindow'
import useMeasure from 'react-use-measure'
import {
  WidgetContainer,
  WidgetContentWrapper,
} from '@plastiq/react-components'
import { AnimatePresence } from 'framer-motion'

import PayerRoutes from './payer/routes'
import PaymentRoutes from './payment/routes'
import RecipientRoutes from './recipient/routes'
import PaymentMethodRoutes from './payment-method/routes'
import Loader from './components/Loader'
import Error from './components/Error'
import { WORKFLOW_TYPES } from './constants'
import { buildError, isCardCaptureWorkflowType } from './utils/helpers'
import ContentAnimation from './components/ContentAnimation'
import eventService from './utils/event/EventService'
import { EVENT } from './utils/event/constants'

function App() {
  const app = useSelector(state => state?.app)
  const location = useLocation()
  if (app.error) return <Error error={app.error} />
  if (
    !app.isLoading &&
    !Object.values(WORKFLOW_TYPES).includes(app.workflowType)
  ) {
    const error = buildError('Invalid workflow type provided at initialization')
    return <Error error={error} />
  }

  return (
    <div className='App'>
      <WidgetContainer theme={app.theme}>
        <AnimatePresence mode='wait'>
          <Routes location={location} key={location.pathname}>
            <Route path='/' element={<Setup />} />
            <Route path='/*' element={<Home />} />
          </Routes>
        </AnimatePresence>
      </WidgetContainer>
    </div>
  )
}

const Home = () => {
  const [ref, { height }] = useMeasure()
  const location = useLocation()
  return (
    <ContentAnimation
      isFooterVisible={location.pathname.includes('review')}
      height={height}
    >
      <WidgetContentWrapper innerRef={ref}>
        <PayerRoutes />
        <RecipientRoutes />
        <PaymentMethodRoutes />
        <PaymentRoutes />
      </WidgetContentWrapper>
    </ContentAnimation>
  )
}

const Setup = () => {
  const payerId = useSelector(state => state.payer?.selected?.id)
  const isLoading = useSelector(state => state.app.isLoading)
  const recipientId = useSelector(state => state.recipient?.selected?.id)
  const workflowType = useSelector(state => state.app.workflowType)
  const prevRoute = useLocation()

  eventService.handle(EVENT.LOGIN, {
    payerId,
    workflowType,
    recipientId,
  })

  if (isLoading) return <Loader />

  if (isCardCaptureWorkflowType(workflowType)) {
    if (!payerId) {
      const error = buildError('Missing required payerId')
      return <Error error={error} />
    }
    return <Navigate to='/payment-method' state={{ prevRoute }} />
  }

  // default to `checkout` workflow type
  if (payerId && recipientId) {
    return <Navigate to='/payment-method' state={{ prevRoute }} />
  }
  if (payerId) {
    return <Navigate to='/recipient' state={{ prevRoute }} />
  }
  return <Navigate to='/payer' state={{ prevRoute }} />
}

export default App
