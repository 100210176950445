import { ErrorOutline } from '@mui/icons-material'
// import { useSelector } from 'react-redux'

function Error() {
  // const paymentError = useSelector(state => state.payment.error)

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <h2>Error Submitting Payment</h2>
        <ErrorOutline style={{ width: '84px', height: '84px' }} />
        <p>There was an Error submitting your payment.</p>
      </div>
    </>
  )
}

export default Error
