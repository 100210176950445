import { Routes, Route } from 'react-router-dom'
import PayerInformation from './pages/PayerInformation'
import Payer from './'
import React from 'react'

function PayerRoutes() {
  return (
    <Routes>
      <Route path='payer' element={<Payer />}>
        <Route index={true} element={<PayerIndex />} />
      </Route>
    </Routes>
  )
}

function PayerIndex() {
  return <PayerInformation />
}

export default PayerRoutes
