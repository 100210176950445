import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { PaymentMethodList as PaymentMethodListForm } from '@plastiq/react-components'
import { setFormData, resetFormData } from '../reducer'
import { handlePostMessage } from '../../utils/helpers'
import BodyAnimation from '../../components/BodyAnimation'
import eventService from '../../utils/event/EventService'
import { EVENT } from '../../utils/event/constants'

function PaymentMethodList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const isStart = state?.prevRoute?.pathname === '/'
  const paymentMethodList = useSelector(state => state.paymentMethod.list)
  const paymentMethod = useSelector(state => state.paymentMethod.selected)
  const { parentOrigin, theme = {} } = useSelector(state => state?.app)
  const dropdownList = paymentMethodList.map(paymentMethod => {
    const { brand, expirationMonth, expirationYear, last4 } =
      paymentMethod.data || {}
    return {
      value: paymentMethod.id,
      brand: brand,
      last4: last4,
      expMonth: expirationMonth,
      expYear: expirationYear,
    }
  })
  const form = useForm()

  const selectedPaymentMethod = {
    value: paymentMethod?.id,
    brand: paymentMethod?.data?.brand,
  }

  const handleOnSubmit = data => {
    const { paymentMethod: paymentMethodId } = data
    const selectedPaymentMethod = paymentMethodList.find(
      paymentMethod => paymentMethod.id === paymentMethodId,
    )

    dispatch(setFormData(selectedPaymentMethod))

    eventService.handle({
      eventName: EVENT.PAYMENT_METHOD_SUBMIT,
      eventProperties: {
        paymentMethodId,
      },
    })

    navigate('/payment/details')
  }

  const handleSubmit = form.handleSubmit(handleOnSubmit)

  const handleClickCreate = () => {
    dispatch(resetFormData())
    navigate('/payment-method/info')
  }

  const handleClickBack = () => {
    handlePostMessage({}, parentOrigin, 'PAYMENT_METHOD', 'BACK')
    navigate(-1)
  }

  return (
    <BodyAnimation>
      <form onSubmit={handleSubmit}>
        <PaymentMethodListForm
          theme={theme}
          isStart={isStart}
          form={form}
          onSubmit={handleSubmit}
          paymentMethods={dropdownList}
          paymentMethod={selectedPaymentMethod}
          onClickCreate={handleClickCreate}
          onClickBack={handleClickBack} // TODO: always hide back if previous step was <setup>
        />
      </form>
    </BodyAnimation>
  )
}

export default PaymentMethodList
