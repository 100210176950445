import { get, post, patch } from './utils'
import { CONNECT_ENDPOINTS } from './constants'

const fetchPayer = async id => {
  const url = `${CONNECT_ENDPOINTS.PAYERS}/${id}`
  return get(url)
}

const createPayer = async body => {
  return post(CONNECT_ENDPOINTS.PAYERS, body)
}

const updatePayer = async (id, body) => {
  const url = `${CONNECT_ENDPOINTS.PAYERS}/${id}`
  return patch(url, body)
}

export { createPayer, updatePayer, fetchPayer }
